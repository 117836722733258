import { stringify } from 'query-string';
import get from 'lodash/get';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  HttpError,
} from 'react-admin';

import axios from 'axios';
import { apiUrl } from './config/api';
import { KEYS } from './authProvider';
import { ROLES_KEYS } from './config/constants';

const DAY_OF_WEEK = {
  Poniedziałek: 1,
  Wtorek: 2,
  Środa: 3,
  Czwartek: 4,
  Piątek: 5,
  Sobota: 6,
  Niedziela: 7,
};

function convertHTTPResponse(response, type, resource) {
  switch (type) {
    case GET_LIST:
      if (resource === 'matrix') {
        const data = response.data.map((item) => {
          const bookingsByDay = item.bookingsByDay.map((bbd) => {
            const weekDay = DAY_OF_WEEK[bbd.dateString];
            const availability = item.availability.find(
              (a) => a.day === weekDay,
            );

            const bookings = bbd.bookings.map((booking) => {
              const fromDate = new Date(booking.from);
              const toDate = new Date(booking.to);
              const fromSeconds =
                fromDate.getHours() * 3600 + fromDate.getMinutes() * 60;
              const toSeconds =
                toDate.getHours() * 3600 + toDate.getMinutes() * 60;

              return {
                ...booking,
                fromSeconds,
                toSeconds: toSeconds === 0 ? 86400 : toSeconds,
              };
            });

            return {
              ...bbd,
              availability: availability || false,
              bookings,
            };
          });

          return {
            ...item,
            bookingsByDay,
            id: item._id || item.ID,
          };
        });

        return {
          data,
          total: response.totalCount || response.data.length,
        };
      }

      if (resource === 'settings') {
        const [data] = response.data;
        const d = Object.keys(data.value).map((city, index) => ({
          id: `${data._id}-${index}`,
          city,
          numberOfRecords: data.value[city],
        }));

        return {
          data: d,
          total: d.length,
        };
      }

      return {
        data: response.data.map((item) => ({
          ...item,
          id: item._id || item.ID,
        })),
        total: response.totalCount || response.data.length,
      };
    case GET_MANY:
      return {
        data: response.data.map((item) => ({
          ...item,
          id: item._id || item.ID,
        })),
        total: response.data.totalCount,
      };
    case GET_ONE: {
      if (resource === 'settings') {
        const { data } = response;
        const d = Object.keys(data.value).map((city, index) => ({
          id: `${data._id}-${index}`,
          city,
          numberOfRecords: data.value[city],
        }));

        return {
          data: {
            cities: d,
            id: response.id,
          },
        };
      }

      return {
        data: {
          ...response.data,
          id: response.data._id,
          ...(response.data.images
            ? {
                images: response.data.images.map((image) => ({
                  src: `${image.src}`,
                  name: image.name,
                })),
              }
            : {}),
          ...(response.data.mapImage
            ? {
                mapImage: {
                  src: `${response.data.mapImage.src}`,
                  name: response.data.mapImage.name,
                },
              }
            : {}),
        },
      };
    }
    case CREATE:
      return { data: { ...response.data, id: get(response, 'data._id') } };
    case UPDATE:
      return { data: { ...response } };
    case DELETE:
      return { data: { ...response } };
    default:
      return { data: response.data };
  }
}

export default async (type, resource, params) => {
  let url = '';
  let query = '';
  let method = 'GET';

  switch (type) {
    case GET_LIST: {
      const userRole = localStorage.getItem(KEYS.ROLE);
      const adminId = localStorage.getItem(KEYS.ADMIN_ID);
      const { page, perPage } = params.pagination;
      const { q: phrase, ...filters } = params.filter;

      query = {
        limit: resource === 'orders' ? Number.MAX_SAFE_INTEGER : perPage,
        page,
        sort: params.sort.field,
        order: params.sort.order,
        phrase,
        ...(userRole === ROLES_KEYS.ROOM_VIEWER ? { viewableBy: adminId } : {}),
        ...filters,
      };

      url = `${apiUrl}/${resource}?${stringify(query)}`;

      break;
    }
    case GET_ONE:
      url = `${apiUrl}/${resource}/${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/${resource}`;
      method = 'post';
      break;
    case GET_MANY: {
      if (resource === 'orders') {
        const userRole = localStorage.getItem(KEYS.ROLE);

        if (userRole === ROLES_KEYS.ROOM_VIEWER) break;

        url = `${apiUrl}/${resource}?${stringify({
          id: params.ids,
          limit: Number.MAX_SAFE_INTEGER,
        })}`;
      } else {
        url = `${apiUrl}/${resource}?${stringify({ id: params.ids })}`;
      }
      break;
    }
    case UPDATE:
      method = 'PATCH';
      url = `${apiUrl}/${resource}/${params.id}`;
      break;
    case DELETE:
      url = `${apiUrl}/${resource}/${params.id}`;
      method = 'delete';
      break;
    case DELETE_MANY:
      url = `${apiUrl}/${resource}?${stringify({ id: params.ids })}`;
      method = 'delete';
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  try {
    let payload = get(params, 'data', {});

    // update settings
    if (resource === 'settings' && method === 'PATCH') {
      const { cities } = params.data;
      const value = {};

      cities.forEach((city) => {
        value[city.city] = city.numberOfRecords;
      });

      payload = {
        value,
      };
    }

    const { data } = await axios({
      url,
      method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: {
        ...payload,
      },
    });
    const result = convertHTTPResponse(
      { id: params.id, ...data },
      type,
      resource,
    );
    return result;
  } catch (error) {
    let { message } = error.response.data;

    if (
      error.response.data.errorMessages &&
      error.response.data.errorMessages.length > 0
    ) {
      message = error.response.data.errorMessages.join(', ');
    }

    throw new HttpError(
      message,
      error.response.status,
      error.response.data.errors,
    );
  }
};
